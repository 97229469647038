import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import HeroSubpage from "components/HeroSubpage"

import { Contact } from "page_components/home"
import { Cooperation } from "page_components/carrier-zone"

const CarrierZonePage = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]

  return (
    <Layout
      seo={{
        title: pageData.title,
      }}
      location={location}
    >
      <HeroSubpage
        title={pageData.title}
        description={pageData.acfCarrierZone.heroDescription}
      />
      <Cooperation pageData={pageData} />
      <Contact isSubpage />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxODI=" } }) {
      nodes {
        id
        title
        acfCarrierZone {
          contentDescription
          contentHeader
          featuresHeader
          featuresList {
            text
          }
          fleetHeader
          fleetList {
            header
            image {
              sourceUrl
            }
            details {
              text
            }
          }
          heroDescription
        }
      }
    }
  }
`

export default CarrierZonePage
